import FormStep from "components/Form/FormStep"
import LargeButton from "components/LargeButton"
import routes from "config/routes"
import useBAE from "hooks/useBAE"
import useSession from "hooks/useSession"

import { useHistory } from "react-router"

const year = new Date().getFullYear() - 1

const AcceptBAE = () => {

  const { currentUser } = useSession()

  const history = useHistory()

  const { bae, isLoadingBAE, acceptBAE, isAcceptingBAE, BAEAccepted } = useBAE()

  console.log(currentUser)

  if (BAEAccepted) return <FormStep
    title="¡Felicidades por unirte al equipo de asociados de Decathlon United! "
    subtitle="Tu transacción está siendo validada en este momento.

    Si es tu primera vez como asociado, ten en cuenta que el proceso culminará una vez adjuntes tu poder autenticado.

    Si ya lo has hecho, te damos la bienvenida una vez más.

    Recuerda que este proceso se hará efectivo hasta el 31 de agosto 2025."
    onBack={() => history.push(routes.home)}>
    <div className="flex">
      <LargeButton onClick={() => history.push(routes.home)}>Volver a mis transacciones</LargeButton>
    </div>
  </FormStep>

  if (isLoadingBAE) return <div>Cargando tu BAE</div>

  if (!bae) return <FormStep title="No tienes BAE para aceptar" onBack={() => history.push(routes.home)}>
    <div className="flex">
      <LargeButton onClick={() => history.push(routes.home)}>Volver a mis transacciones</LargeButton>
    </div>
  </FormStep>

  return (
    <FormStep title="Acepta tu BAE" onBack={() => history.push(routes.home)}>
      <div className="flex flex-col">
        {
          currentUser?.company === "decathlon"
            ? <div className="text-gray-700 mt-2 space-y-2">
              <p >DECATHLON COLOMBIA S.A.S., quiere compartir con usted una parte del éxito logrado durante el año {year}.</p>

              <p >Por lo tanto, de forma excepcional DECATHLON COLOMBIA S.A.S ha decidido otorgarle el derecho a obtener un incentivo que corresponde a un 12% de su salario bruto anual del 2024, ajustado con base en las horas efectivamente trabajadas desde el 1 de enero de 2024 hasta el 31 de diciembre de 2024, exceptuando las horas no laboradas por incapacidades o cualquier otro ausentismo relacionado en la política de remuneración y movilidad, así:</p>

              <p className="font-medium"> Salario bruto ajustado a la horas efectivamente trabajadas * 12 meses * 12% = BAE 2024.</p>

              <p>El equivalente en acciones DISP al resultado obtenido será la cantidad de acciones que serán adquiridos por DECATHLON COLOMBIA S.A.S. a su favor.</p>

              <p className="font-medium">El valor del incentivo que le corresponde en caso de aceptarlo es de <span className="text-xl font-bold">{bae.amount}</span> instrumentos DISP.</p>

              <p>
                En caso de aceptar esta modalidad, deberá adherirse a los estatutos de DISP y a las Reglas y Regulaciones del Plan de Participación Accionaria de Decathlon International Shareholding Plan.
              </p>
              <p className="text-sm italic">
                La materialización de este derecho, se encuentra sometida al cumplimiento de las siguientes condiciones:

                Permanecer vinculado mediante un contrato de trabajo de duración indefinida a DECATHLON COLOMBIA S.A.S y/o cualquiera de las filiales del Grupo DECATHLON hasta el 30 de junio de 2025.
                El incentivo se verá reflejado a partir del 30 de junio de 2025, fecha en la cual se materializa la compra de acciones DISP.

              </p>
            </div>

            : <div className="text-gray-700 mt-2 space-y-2">
              <p >DECOAM S.A.S., quiere compartir con usted una parte del éxito logrado durante el año {year}.</p>

              <p >
                Por lo tanto, de forma excepcional DECOAM S.A.S  ha decidido otorgarle el derecho a obtener un incentivo que corresponde a un 20% de su salario bruto anual del 2024, ajustado con base en las horas efectivamente trabajadas desde el 1 de enero de 2024 hasta el 31 de diciembre de 2024, exceptuando las horas no laboradas por incapacidades o cualquier otro ausentismo relacionado en la política de remuneración y movilidad, así:
              </p>

              <p className="font-medium"> Salario bruto ajustado a la horas efectivamente trabajadas * 12 meses * 20% = BAE 2024</p>

              {/* <p className="font-medium"> Y una prima excepcional que corresponde a tres primas legales del año 2022, exceptuando las horas no laboradas por incapacidades.</p> */}

              <p>El equivalente en acciones DISP al resultado obtenido será la cantidad de acciones que serán adquiridos por DECOAM S.A.S. a su favor.</p>

              <p className="font-medium">El valor del incentivo que le corresponde en caso de aceptarlo es de <span className="text-xl font-bold">{bae.amount}</span> instrumentos DISP.</p>

              <p>
                En caso de aceptar esta modalidad, deberá adherirse a los estatutos de DISP y a las Reglas y Regulaciones del Plan de Participación Accionaria de Decathlon International Shareholding Plan.
              </p>
              <p className="text-sm italic">
                La materialización de este derecho, se encuentra sometida al cumplimiento de las siguientes condiciones:

                Permanecer vinculado mediante un contrato de trabajo de duración indefinida a DECOAM S.A.S y/o cualquiera de las filiales del Grupo DECATHLON hasta el 30 de junio de 2025.
                El incentivo se verá reflejado a partir del 30 de junio de 2025, fecha en la cual se materializa la compra de acciones DISP.


              </p>
            </div>
        }

        <div className="flex sticky bottom-5 md:bottom-10">
          <LargeButton isLoading={isAcceptingBAE} onClick={acceptBAE}>Aceptar mi BAE</LargeButton>
        </div>
      </div>
    </FormStep >
  )

}

export default AcceptBAE


