import { Transition } from "@headlessui/react"
import Button from "components/Button"
import useBAE from "hooks/useBAE"

import giftIllustration from "assets/illustrations/gift.png"
import { SparklesIcon } from "@heroicons/react/outline"
import { useHistory } from "react-router-dom"
import routes from "config/routes"
import useSession from "hooks/useSession"

const BAE = () => {

  const { bae } = useBAE()

  const history = useHistory()
  const { currentUser } = useSession()

  if (!bae) return null

  return (
    <Transition
      show={!!bae}
      enter="transform transition duration-[400ms]"
      enterFrom="opacity-0 rotate-[-120deg] scale-50"
      enterTo="opacity-100 rotate-0 scale-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 rotate-0 scale-100 "
      leaveTo="opacity-0 scale-95 "
    >
      <div className="rounded-xl bg-white transition hover:shadow-lg p-4 border">
        <div className="flex flex-col items-center md:flex-row">
          <img src={giftIllustration} className="h-40 w-40 md:h-20 md:w-auto object-contain mr-2" />
          <div className="flex flex-col justify-between flex-1">
            {
              currentUser?.company == "decathlon" ?
                <p className="font-medium">Tienes un BAE de 12% de {bae?.amount} acciones pendiente de aceptación</p> :
                <p className="font-medium">Tienes un BAE de 20% de {bae?.amount} acciones pendiente de aceptación</p>
            }
            {/* <p className="font-medium">Tienes un BAE de 20% + 3 PRIMAS EXCEPCIONALES 2023 de {bae?.amount} acciones pendiente de aceptación</p> */}
            <div className="flex justify-end mt-4 ">
              <div className="w-full md:w-auto">
                <Button onClick={() => history.push(routes.bae)}><SparklesIcon className="h-4 mr-2" />Aceptar mi BAE</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default BAE
